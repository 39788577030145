import React from 'react';
import './Footer2.css'; // You can define your footer styles in a separate CSS file if needed
import linkedin from './images/socials_linkedin.png'
import instagram from './images/socials_instagram.png'
import tiktok from './images/socials_tiktok.png'
import xIcon from './images/socials_x.png';

function Footer2() {
    return (
        <footer className="footer2">
            <div className="footer-content2">
                <span className="footer-left">
                    FIND US ON:
                    <div className="Icons">
                        <a href="https://www.linkedin.com/company/welleo/" target="_blank" rel="noopener noreferrer" className="FooterLinks2">
                            <img src={linkedin} alt="LinkedIn" className="logos" width="512" height="512" />
                        </a>
                        <a href="https://www.instagram.com/staflyapp/" target="_blank" rel="noopener noreferrer" className="FooterLinks2">
                            <img src={instagram} alt="Instagram" className="logos" width="512" height="512" />
                        </a>
                        <a href="https://www.tiktok.com/@staflyapp" target="_blank" rel="noopener noreferrer" className="FooterLinks2">
                            <img src={tiktok} alt="TikTok" className="logos" width="512" height="512" />
                        </a>
                        <a href="https://x.com/staflyapp" target="_blank" rel="noopener noreferrer" className="FooterLinks2">
                            <img src={xIcon} alt="X (Twitter)" className="logos" width="512" height="512" />
                        </a>
                    </div>
                </span>
                <span className="footer-right2">
                    &copy; 2024 Welleo AB<br></br>
                    All rights reserved<br></br>
                    Org.nr: 559474-8500<br></br>
                    info@staflyapp.com<br></br>
                    Stafly is a registered trademark<br></br>
                </span>
            </div>
        </footer>
    );
}

export default Footer2;
