import "./WeAreWelleo.css";
import '../App.css';
import Footer2 from './Footer2';
import { FaLinkedin } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import Footer from "./Footer";
import { isMobile } from 'react-device-detect';
import logo from "./images/welleo_logo_transp.png";
function CAREERS({ navigateTo }) {
    return (
        <div className="WeAreWelleo">
            <Helmet>
                <title>About Us - Values of Stafly</title>
                <meta name="description" content="At Welleo, creators of Stafly, our core values serve as the foundation of our mission to promote a healthier world."/>
                <link rel="canonical" href="https://staflyapp.com/aboutus" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://staflyapp.com/aboutus" />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <Body1 />
            <Footer2></Footer2>
        </div>
    );
}

const OpenPDF = (event) => {

    const pdfUrl = '/CodeofConduct.pdf';

    if (isMobile) {
        window.location.href = pdfUrl;
    } else {
        window.open(pdfUrl, '_blank');
    } 
};

function Body1({ navigateTo }) {
    return (
        <div className="WRW-Container">
            <div className="WRW-Item">
                <div className="WRW-Item-Item1">
                    <h1 className="BolderH3"><span className="colored-text3">ABOUT US</span></h1>
                    <h2 className="BolderP">At Welleo, the company behind Stafly, we are guided by a set of values that form the cornerstone of our commitment to fostering a healthier world. These values permeate every aspect of our operations, influencing how we conduct ourselves as individuals and as a collective community.</h2>
                </div>
                <div className="WRW-Item-Item2">
                    <img className="WRW-Img1" src={require('./images/pexels-mastercowley-1199607.jpg')} alt="Two active, high performing and healthy individuals signifing the values of Stafly, Welleo and the Staflyapp" width="1280" height="720" />
                </div>
            </div>
            <div className="WRW-Title1">
                <h2 className="OurValues">OUR VALUES</h2>
            </div>
            <div className="WRW-Containers2">
            </div>
            <div className="WRW-Item WRW-Item1">
                <img className="WRW-Img" src={require('./images/wellness_image.png')} alt="Icon of wellness, health and wellbeing" width="401" height="400" />
                <h3 className="Values">WELLNESS</h3>
                <p>We are committed to promoting holistic well-being, encompassing physical, mental, and emotional health. Our products and services are designed to support individuals on their journey to optimal wellness,
                    fostering habits that promote longevity and vitality.</p>
            </div>
            <div className="WRW-Item WRW-Item2">
                <img className="WRW-Img" src={require('./images/innovation__image.png')} alt="Icon of innovation, great ideas and creativity" width="401" height="400" />
                <h3 className="Values">INNOVATION</h3>
                <p>We embrace innovation as a driving force behind our quest for excellence. We continuously seek new and creative ways to empower individuals to achieve their fitness and wellness goals, pushing the boundaries
                    of what is possible in the health and fitness landscape.</p>
            </div>
            <div className="WRW-Item WRW-Item3">
                <img className="WRW-Img" src={require('./images/integrity_image.png')} alt="Icon of integrity, honesty and transparancy" width="401" height="400" />
                <h3 className="Values">INTEGRITY</h3>
                <p>We hold ourselves to the highest standards of honesty, transparency, and ethical behavior. Integrity is the bedrock upon which we build trust with our stakeholders, and we recognize that every
                    decision and action we take must be grounded in integrity.</p>
            </div>
            <div className="WRW-Item WRW-Item4">
                <img className="WRW-Img" src={require('./images/collaboration_image.png')} alt="Icon of collaboration, teamwork and diverse perspectives brought together" width="401" height="400" />
                <h3 className="Values">COLLABORATION</h3>
                <p>By fostering an environment of collaboration, we bring together diverse perspectives and expertise to achieve our shared goals. We actively seek out partnerships and collaborations with individuals,
                    and organizations that share our passion for wellness.</p>
            </div>
            <div className="WRW-Item WRW-Item5">
                <img className="WRW-Img" src={require('./images/community_image.png')} alt="Icon of the relationships we build through community, feedback and concerns" width="401" height="400" />
                <h3 className="Values">COMMUNITY</h3>
                <p>We actively engage with our community, listening to their needs, feedback, and concerns. We strive to be responsive and accountable, building meaningful relationships
                    based on mutual respect, trust, and collaboration.</p>
            </div>
            <div className="WRW-Item WRW-Item6">
                <img className="WRW-Img" src={require('./images/CoC_image.png')} alt="Two happy men high fiving for the code of conduct" width="401" height="400" />
                <h3 className="Values">CODE OF CONDUCT</h3>
                <p>Read our full Code of Conduct to familiarize
                    yourself with what we believe and stand for.</p>
                <button className="SignUpButton" onClick={() => OpenPDF()}>READ MORE</button>
            </div>

            <div className="WRW-Title2">
                <h2 className="OurTeam">OUR TEAM</h2>
            </div>
            <div className="WRW-Team WRW-Item7">
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/emil-bergqvist-699a04213/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_emil.png')} alt="Welleo team member Emil Bergqvist" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/marcus-videg%C3%A5rd-17586a23a" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_marcus.png')} alt="Welleo team member Marcus Videg�rd" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/hannes-jerdborg-47302421a/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_hannes.png')} alt="Welleo team member Hannes Jerdborg" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/oliver-m%C3%A1rkus-johansson-b29659235/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_oliver.png')} alt="Welleo team member Oliver Markus-J" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/pontus-engstr%C3%B6m-954676317" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_pontus.png')} alt="Welleo team member Pontus Engstr�m" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/linus-bergqvist-63600526a/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_linus.png')} alt="Welleo team member Linus Bergqvist" className="team-image" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
                <div className="team-member">
                    <a href="https://www.linkedin.com/in/daniel-lopez-perez/" className="linkedin-link" target="_blank">
                        <img src={require('./images/team_daniel.png')} alt="Welleo team member Daniel Lopez-P" className="team-image team-daniel" loading="lazy" width="1000" height="1296" />
                    </a>
                </div>
            </div>


            {/*<div className="WRW-Item WRW-Item1">*/}
            {/*    <img className="WRW-Img" src={require('./images/wellness_image.png')} />*/}
            {/*    <h3 className="Values">WELLNESS</h3>*/}
            {/*    <p>We are committed to promoting holistic well-being, encompassing physical, mental, and emotional health. Our products and services are designed to support individuals on their journey to optimal wellness,*/}
            {/*        fostering habits that promote longevity and vitality.</p>*/}
            {/*</div>*/}
        </div>
    );
}

export default CAREERS;