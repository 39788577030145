import './ContactUs.css'; 
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer2 from './Footer2';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const ContactForm = () => {
    const [opacity, setOpacity] = useState(0);
    const [email, setEmail] = useState('');
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Invalid email';
        }


        const domainRegex = /\.(com|se|ru|net|org|info|biz|gov|edu|mil|int|name|pro|aero|coop|museum|xyz|top|site|online|tech|store|art|blog|club|design|space|wiki|shop|us|uk|ca|de|fr|es|it|nl|au|ch|jp|cn|in)$/i;
        if (!domainRegex.test(email)) {
            return 'Invalid email';
        }

        return '';
    };

    const handleChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        const validationError = validateEmail(newEmail);
        setMessage(validationError);
        setMessageType(validationError ? 'error' : '');
    };
    const handleChange2 = (e) => {
        const newFirstName = e.target.value;
        setFirstname(newFirstName);
    };
    const handleChange3 = (e) => {
        const newLastName = e.target.value;
        setLastname(newLastName);
    };
    const handleChange4 = (e) => {
        const newMessage = e.target.value;
        setContactMessage(newMessage);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const validationError = validateEmail(email);
        if (validationError) {
            setMessage(validationError);
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch('https://us-central1-strengthhub-app.cloudfunctions.net/ContactUs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, isChecked, isChecked2 }),
            });

            if (response.ok) {
                setMessage('Email submitted successfully!');
                setMessageType('success');
            } else {
                const errorData = await response.json();
                if (errorData.message === "Email already exists") {
                    setMessage('Email already exists');
                } else {
                    setMessage(`Failed to submit email: ${errorData.message}`);
                }
                setMessageType('error');
            }
        } catch (error) {
            setMessage(`Failed to submit email: ${error.message}`);
            setMessageType('error');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };


    return (
        <div className="HomePage3">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="overlay"></div>
            <div className="ContactBody">
                <div className="Content1">
                    <h1 className="H12">CONTROL YOUR HEALTH AND PERFORMANCE</h1>
                    <p className="SignUpP">We will notify you when Stafly is available.</p>
                    <form onSubmit={handleSubmit} className="InputButton">
                        <input
                            type="email"
                            placeholder="Enter Email"
                            className="Input-Body2"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="firstname"
                            placeholder="Enter first name"
                            className="Input-Body2"
                            value={firstname}
                            onChange={handleChange2}
                            required
                        />
                        <input
                            type="lastname"
                            placeholder="Enter last name"
                            className="Input-Body2"
                            value={lastname}
                            onChange={handleChange3}
                            required
                        />
                        <input
                            type="contactMessage"
                            placeholder="Enter Message"
                            className="Input-Body2"
                            value={contactMessage}
                            onChange={handleChange4}
                            required
                        />
                        <button type="submit" className="SignUpButton buttonSign" >
                            {isLoading ? 'Processing...' : 'SIGN UP'}
                        </button>
                        <div className="ResponseMessage">
                            {message && <p className={`message ${messageType}`}>{message}</p>}
                        </div>
                    </form>

                </div>
                {/*{isLoading && <div className="loading-spinner"></div>} */}
            </div>
            {/*<Footer2/>*/}
        </div>
    );
};

export default ContactForm;
