import '../App.css';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function openNav() {
    document.getElementById("myNav").style.height = "100%";
    document.getElementById("hamMenu").style.opacity = "0";
}

function closeNav() {
    document.getElementById("myNav").style.height = "0%";
    document.getElementById("hamMenu").style.opacity = "1";
}
function optionClicked() {
    document.getElementById("myNav").style.height = "0%";
    document.getElementById("hamMenu").style.opacity = "1";
}

function Header({ navigateTo }) {
    const location = useLocation();

    return (
        <div className="HeaderWrapper">
            <Helmet>
            </Helmet>
            <header className="Header">
                <div className="headerDiv1">
                    <Link to="/" className={location.pathname === '/Homepage' ? 'TWA4 active' : 'TWA4'}>
                        <img className="HeaderLogo" src={require('./images/stafly_full_transp.png')} alt="Stafly Logo" width="789" height="300" />
                    </Link>
                </div>
                <div className="headerDiv2">
                    <Link to="/" className={location.pathname === '/' ? 'TWA active' : 'TWA'}>THE STAFLY APP</Link>
                    <div>
                        <Link to="/careers" className={location.pathname === '/careers' ? 'WAW active' : 'WAW'}>
                            <span className="TagTitle">CAREERS<span className="tag">New</span></span>
                        </Link>
                    </div>
                    <Link to="/aboutus" className={location.pathname === '/aboutus' ? 'WRW active' : 'WRW'}>ABOUT US</Link>
                    <Link to="/signup" className={location.pathname === '/signup' ? 'SignUpButton active' : 'SignUpButton'}>SIGN UP</Link>
                    <span id="hamMenu" className="HamburgerButton" onClick={() => openNav()}>&#9776;</span>
                </div>
            </header>
            <div id="myNav" className="overlay2">
                <button href="#" className="closebtn" onClick={(event) => { event.preventDefault(); closeNav(); }}>&times;</button>
                <div className="overlay2-content">
                    <Link to="/" className="TWA2" onClick={() => optionClicked()}>THE STAFLY APP</Link>
                    <Link to="/careers" className="WAW2" onClick={() => optionClicked()}>CAREERS</Link>
                    <Link to="/aboutus" className="WRW2" onClick={() => optionClicked()}>ABOUT US</Link>
                    <Link to="/signUp" className="SignUpButton" onClick={() => optionClicked()}>SIGN UP</Link>
                </div>
            </div>
        </div>
    );
}

export default Header;
